import * as React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { graphql } from 'gatsby';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';
import SimpleReactLightbox from 'simple-react-lightbox';

import { FadeIn } from '~/components/animations';
import { Certificate } from '~/components/certificate';
import { LightBox } from '~/components/lightbox';
import { PageHeading } from '~/components/page-heading';
import { getMappedCertificates, getMappedHeading } from '~/core/data-mappers';
import { Container } from '~/theme/helpers';

const CertificatesTemplate = ({
  data: {
    pageData,
    allWpCertificate: { certificatesNodes },
  },
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const heading = getMappedHeading(pageData);
  const certificates = getMappedCertificates(certificatesNodes);

  return (
    <SimpleReactLightbox>
      <Seo post={pageData} />

      <PageHeading
        description={heading.description}
        title={heading.title}
        headingTag="h1"
      />

      <Container>
        <Row between="lg" center="md">
          {certificates.map((certificate, index) => (
            <Col lg={4} md={6} xs={12} key={certificate.title}>
              <FadeIn delay={index * 0.1}>
                <Certificate
                  certificate={certificate}
                  onClick={() => setActiveIndex(index)}
                />
              </FadeIn>
            </Col>
          ))}
        </Row>
      </Container>

      <LightBox
        images={certificates.map(cerf => cerf.image)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </SimpleReactLightbox>
  );
};

export const query = graphql`
  query($language: String, $locale: String) {
    pageData: wpPage(
      slug: { eq: "certificates" }
      locale: { locale: { eq: $locale } }
    ) {
      ...YoastSiteFragment
      ...PageHeadingFragment
    }
    allWpCertificate(filter: { locale: { locale: { eq: $locale } } }) {
      certificatesNodes: nodes {
        title
        certificatesFields {
          description
          image {
            localFile {
              thumbnail: childImageSharp {
                gatsbyImageData(height: 320, placeholder: BLURRED)
              }
              full: childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              placeholder: childImageSharp {
                gatsbyImageData(height: 4, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default CertificatesTemplate;
